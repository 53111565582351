import React from "react"
import PressReleaseMainBanner from "../templates/PressRelease/pressReleaseMainBanner"
import SearchBox from "../templates/PressRelease/searchBox"
import PressReleaseMainContent from "../templates/PressRelease/pressReleaseMainContent"


export default function Press() {
    return (
      <div>
        {/* <PressRelease2Home /> */}
        <PressReleaseMainBanner/>
        <SearchBox />
        <PressReleaseMainContent />
      </div>
    )
  }