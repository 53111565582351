import React from 'react'
import './pressReleaseMainContent.css'
import adImage01 from '../../images/insta-ad.png'
import NewImage01 from '../../images/news-image1.png'
import NewsImage02 from '../../images/pressReleaseImg2.jpeg'
import NewsImage03 from '../../images/pressReleaseImg3.jpg'
import NewsImage04 from '../../images/NewsImage04.jpg'
import NewsImage05 from '../../images/NewsImage05.jpg'


import { FaArrowRight } from "react-icons/fa";

const PressReleaseMainContent = () => {
  return (
    <div className='press-main-content-container'>
        <div className='press-main-content-container-left'>
            <div className="press-main-left-top">
                <h3>Latest News</h3>
                <div className="press-main-left-news-container">
                    <div className="press-main-left-news">
                        <div className='press-main-left-news-image'>
                            <img src={NewImage01} alt="" />
                        </div>
                        <div className='press-main-left-news-content'>
                            <small>16 Feb</small>
                            <p>CIC Agri honours business partners...</p>
                        </div>
                    </div>
                    <div className="press-main-left-news">
                        <div className='press-main-left-news-image'>
                            <img src={NewsImage02} alt="" />
                        </div>
                        <div className='press-main-left-news-content'>
                            <small>08 Feb</small>
                            <p>CIC Holdings PLC Kicked Off the Second...</p>
                        </div>
                    </div>
                    <div className="press-main-left-news">
                        <div className='press-main-left-news-image'>
                            <img src={NewsImage03} alt="" />
                        </div>
                        <div className='press-main-left-news-content'>
                            <small>08 Feb</small>
                            <p>Education Scholarships for Employees Children</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="press-main-left-bottom">
                <h3>Instagram</h3>
                <div className='press-main-left-bottom-image'>
                    <img src={adImage01} alt="" />
                </div>
            </div>
        </div>


        {/* Right Section */}
        <div className='press-main-content-container-right'>
            <div className="press-main-container-right-news">
                <div className="press-main-container-right-news-image">
                    <img src={NewImage01} alt="" />
                    <div className='press-main-container-right-news-image-date'>
                        <p>16 <br />Feb</p>
                    </div>
                </div>
                <div className="press-main-container-right-news-content">
                    <small className='press-main-container-right-news-content-small'>Press Release</small>
                    <h2>CIC Agri honours business partners with Lifetime Awards</h2>
                    <p>CIC Agri Businesses recognized and appreciated two of its long-standing business partners Bavani Stores, Jaffna and Samarasiri Traders, Welimada with ‘Lifetime Awards’ at the Annual New Year Invoicing Ceremony which was held at the Lotus Tower recently. Bavani Stores was founded by Ponnaiyah Krishnananthan also known as Rajan in 1976 and commenced as a...</p>
                    <button className='press-news-target_button'>
                        Read more
                        <FaArrowRight className='press-news-target_button-icon' />
                    </button>
                </div>
            </div>
            <div className="press-main-container-right-news">
                <div className="press-main-container-right-news-image">
                    <img src={NewsImage02} alt="" />
                    <div className='press-main-container-right-news-image-date'>
                        <p>08 <br />Feb</p>
                    </div>
                </div>
                <div className="press-main-container-right-news-content">
                    <small>Press Release</small>
                    <h2>CIC Holdings PLC Kicked Off the Second Strategic Leadership Development Programme with PIM</h2>
                    <p>After completing a very successful programme, the PIM launched the second Strategic Leadership Development Programme (SLDP) specifically to develop the senior managerial staff of CIC Holdings PLC. This program was placed under the broad category of the PIM EDPs (Executive Development Programmes) cluster. It included 38 senior managerial staff of....</p>
                    <button className='press-news-target_button'>
                        Read more
                        <FaArrowRight className='press-news-target_button-icon' />
                    </button>
                </div>
            </div>
            <div className="press-main-container-right-news">
                <div className="press-main-container-right-news-image">
                    <img src={NewsImage03} alt="" />
                    <div className='press-main-container-right-news-image-date'>
                        <p>08 <br />Feb</p>
                    </div>
                </div>
                <div className="press-main-container-right-news-content">
                    <small>Press Release</small>
                    <h2>Education Scholarships for Employees Children</h2>
                    <p>CIC Holdings Awarded Education Scholarships to Employees Children who had excelled in their Academic Activities. Employees Children who excelled in the Grade Five Scholarship Exam, Obtained 9 ‘A’ s at the G C E (O’Level),Obtained 3 “A” s at the G C E (A’Level) exam and gained University entrance to a State University were recognized and rewarded....</p>
                    <button className='press-news-target_button'>
                        Read more
                        <FaArrowRight className='press-news-target_button-icon' />
                    </button>
                </div>
            </div>

            <div className="press-main-container-right-news">
                <div className="press-main-container-right-news-image">
                    <img src={NewsImage04} alt="" />
                    <div className='press-main-container-right-news-image-date'>
                        <p>08 <br />Feb</p>
                    </div>
                </div>
                <div className="press-main-container-right-news-content">
                    <small>Press Release</small>
                    <h2>Swastha Experiential Store is now open!</h2>
                    <p>Visit us at Maitland Crescent, Colombo 07 to shop for your favourite Link Natural’s wellness, healthcare and personal care products for a healthy, long life. Discover Holistic Wellness at Swastha by Link Natural.</p>
                    <button className='press-news-target_button'>
                        Read more
                        <FaArrowRight className='press-news-target_button-icon' />
                    </button>
                </div>
            </div>

            <div className="press-main-container-right-news">
                <div className="press-main-container-right-news-image">
                    <img src={NewsImage05} alt="" />
                    <div className='press-main-container-right-news-image-date'>
                        <p>08 <br />Feb</p>
                    </div>
                </div>
                <div className="press-main-container-right-news-content">
                    <small>Press Release</small>
                    <h2>CIC Holdings posts Rs 1.98 Bn PAT for first six months of FY 2023/24</h2>
                    <p>CIC Holdings PLC continued its onward journey despite a challenging environment to post a Revenue of 34.78 Bn and a Profit After Tax of 1.98 Bn at the end of the first six months of the current financial year 2023/24. As the Country continues to emerge from the economic crisis, yet grappling with a  high cost of living and a declining consumer demand...</p>
                    <button className='press-news-target_button'>
                        Read more
                        <FaArrowRight className='press-news-target_button-icon' />
                    </button>
                </div>
            </div>

            <div></div>
        </div>
    </div>
  )
}

export default PressReleaseMainContent
