import React from 'react';
import './searchBox.css';
import { CiSearch } from "react-icons/ci";

const SearchBox = () => {
  return (
    <div className='press-search-box-container'>
      <div className='press-search-box-left'>
        <input type="text" placeholder='Search'/>
        <CiSearch className='press-search-box-left-icon' size={20}/>
      </div>
      <div className='press-search-box-right'>
        <select>
            <option value="1">Default sorting</option>
        </select>
      </div>
    </div>
  )
}

export default SearchBox
